import React, { useState, useEffect, useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { safeCall } from '../../utils/safeCall'

import useStyles from './styles'


const Modal = (props) => {
  const { className, children, isOpen, onClose, onOpen, imgUrl } = props

  const [stateOpen, setStateOpen] = useState(false)

  const classes = useStyles({ ...props, stateOpen })

  const handleClose = useCallback(() => {
    safeCall(onClose)
    setStateOpen(false)
  }, [onClose])

  const handleOpen = useCallback(() => {
    safeCall(onOpen)
    setStateOpen(true)
  }, [onOpen])

  useEffect(() => {
    if (isOpen === true) {
      handleOpen()
    } else {
      handleClose()
    }
  }, [handleClose, handleOpen, isOpen])

  return (
    <div className={cx(className, classes.container, 'is-notstaggered')}>
      <div className={classes.content}>
        {children}
      </div>
      <div
        className={classes.overlay}
        aria-hidden="true"
      >
        {imgUrl && (
          <div className={classes.imageWrapper}>
            <img
              className={classes.image}
              src={imgUrl}
              aria-hidden="true"
              alt=""
            />
          </div>
        )}
      </div>
    </div>
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  imgUrl: PropTypes.string,
}

Modal.defaultProps = {
  className: null,
  isOpen: false,
  onClose: null,
  onOpen: null,
  imgUrl: null,
}

export default Modal
