import { useCallback, useState, useMemo } from 'react'
import { navigate } from 'gatsby-plugin-intl'
import axios from 'axios'

import routes from '../router/routes'
import router from '../router'
import { sha1 } from '../utils/CryptoUtils'
import * as appConfiguration from '../configuration'

import useConfiguration from './useConfiguration'
import useLocalStorage from './useLocalStorage'


const SUCCESS_GAME_STATUS = ['close', '__close-share-modal__']
const LEAVED_GAME_STATUS = ['terminatebyuser']
const SCREENSHOT_GAME_STATUS = ['snapshotsuccess']

const WAIT_CODES = ['64000007', '64000008', '64000009', '68000042', '82600962', '82647880']
const OVERLOAD_CODES = ['64000010']
const BROWSER_ERROR_CODES = ['82647900']
const TECH_ERROR_CODES = ['62016000', '82647400', '61000003', '60300001', '60300003', '80138201', '82647500', '82647705', '68000041']
const CONNECTION_ERROR_CODES = ['82600963', '82600723', '82600530', '82600952']

const useGame = ({ lang, isVip }) => {
  const { configuration } = useConfiguration()
  const {
    setUserCooldownDate,
    setUserCooldownReason,
    setUserErrorReason,
    WAITING_REASONS,
    ERROR_REASONS,
    setShareImage,
    setShareUrl,
    isShareAvailable,
  } = useLocalStorage()
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const [isTerminatedByUser, setIsTerminatedByUser] = useState(false)
  const isShareModalOpen = useMemo(() => shareModalOpen, [shareModalOpen])
  const TUTORIAL_DISPLAY_TIME = 7000

  const goToWait = useCallback((reason) => {
    const dt = new Date()

    dt.setSeconds(dt.getSeconds() + (isVip ? configuration?.delayGameVip : configuration?.delayGame))
    setUserCooldownDate(dt.toISOString())
    setUserCooldownReason(reason)
    navigate(router(isVip ? routes.vipGameWait.path : routes.gameWait.path))
  }, [setUserCooldownReason, setUserCooldownDate, configuration, isVip])

  const goToOverload = useCallback(() => {
    navigate(router(isVip ? routes.vipGameOverload.path : routes.gameOverload.path))
  }, [isVip])

  const goToError = useCallback((reason) => {
    setUserErrorReason(reason)
    navigate(router(isVip ? routes.vipGameError.path : routes.gameError.path))
  }, [setUserErrorReason, isVip])

  const getShareInformationsApiService = async(uid, l) => {
    try {
      const langCode = l === 'zh' ? 'cn' : 'int'
      const response = await axios({
        method: 'POST',
        url: '/api/share',
        baseURL: appConfiguration.app.url,
        ...appConfiguration.app.basicAuthUser ? [{
          auth: {
            username: appConfiguration.app.basicAuthUser,
            password: appConfiguration.app.basicAuthPassword,
          },
        }] : [],
        params: { client_id: uid, target: langCode },
      })

      return response.data
    } catch (error) {
      throw new Error('Sharing informations unavailable for uid', uid)
    }
  }

  const getShareInformations = useCallback(async(userId) => {
    const infos = await getShareInformationsApiService(userId, lang)

    if (infos.error) {
      throw new Error(`Something went wrong when getting sharing informations: ${infos?.message}`)
    }

    setShareImage(infos.image)
    setShareUrl(infos.index)
  }, [lang, setShareImage, setShareUrl])

  const onGameStatus = useCallback((status, userId) => {
    if (status === 'terminatebyuser') {
      setIsTerminatedByUser(true)
    }

    if (SCREENSHOT_GAME_STATUS.indexOf(status) !== -1) {
      return getShareInformations(userId)
    }

    if (SUCCESS_GAME_STATUS.indexOf(status) !== -1) {
      const isShare = isShareAvailable()

      if (isShare && !isShareModalOpen) {
        setShareModalOpen(true)
        return
      }

      if (isShareModalOpen && status !== '__close-share-modal__') {
        return
      }

      return goToWait(isTerminatedByUser ? WAITING_REASONS.EXIT : WAITING_REASONS.SUCCESS)
    }

    if (LEAVED_GAME_STATUS.indexOf(status) !== -1) {
      const isShare = isShareAvailable()

      if (isShare && !isShareModalOpen) {
        setShareModalOpen(true)
        return
      }

      if (isShareModalOpen && status !== '__close-share-modal__') {
        return
      }

      return goToWait(WAITING_REASONS.EXIT)
    }
  }, [WAITING_REASONS.EXIT, WAITING_REASONS.SUCCESS, getShareInformations, goToWait, isShareAvailable, isShareModalOpen, isTerminatedByUser])

  const onGameError = useCallback((error) => {
    if (WAIT_CODES.indexOf(error.code) !== -1) {
      return goToWait(WAITING_REASONS.QUEUE)
    }

    if (OVERLOAD_CODES.indexOf(error.code) !== -1) {
      return goToOverload()
    }

    if (BROWSER_ERROR_CODES.indexOf(error.code) !== -1) {
      return goToError(ERROR_REASONS.BROWSER)
    }

    if (TECH_ERROR_CODES.indexOf(error.code) !== -1) {
      return goToError(ERROR_REASONS.TECH)
    }

    if (CONNECTION_ERROR_CODES.indexOf(error.code) !== -1) {
      return goToError(ERROR_REASONS.CONNECTION)
    }

    return goToError(ERROR_REASONS.TECH)
  }, [goToWait, goToOverload, goToError, WAITING_REASONS, ERROR_REASONS])

  const getUserId = useCallback(() => {
    const toHash = Date.now.toString() + Math.random().toString()
    const uid = sha1(toHash)

    return uid
  }, [])

  return {
    onGameStatus,
    onGameError,
    getUserId,
    isShareModalOpen,
    TUTORIAL_DISPLAY_TIME,
  }
}

export default useGame
