import React, { useState, useEffect, useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { safeCall } from '../../utils/safeCall'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'

import useStyles from './styles'


const QueueModal = (props) => {
  const { className, isOpen, onClose, onOpen, queueNumber, queueSentence, videoBackgroundSources } = props
  const [stateOpen, setStateOpen] = useState(false)
  const classes = useStyles({ ...props, stateOpen })

  const handleClose = useCallback(() => {
    safeCall(onClose)
    setStateOpen(false)
  }, [onClose])

  const handleOpen = useCallback(() => {
    safeCall(onOpen)
    setStateOpen(true)
  }, [onOpen])

  useEffect(() => {
    if (isOpen === true) {
      handleOpen()
    } else {
      handleClose()
    }
  }, [handleClose, handleOpen, isOpen])

  return (
    <div className={cx(className, classes.container, 'is-notstaggered')}>
      <Icon
        className={classes.logoMain}
        icon={iconsKeys.Logo}
      />
      <div className={classes.countDownWrapper}>
        <div className={classes.countdown}>{queueNumber}</div>
        <svg
          className={classes.countDownImage}
          aria-hidden="true"
          width="232"
          height="232"
          viewBox="0 0 232 232"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="116"
            cy="116"
            r="93"
            transform="rotate(165 116 116)"
            stroke="url(#paint0_linear)"
            strokeWidth="2"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="114.292"
              y1="280.881"
              x2="258.054"
              y2="134.449"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop
                offset="0.46004"
                stopColor="white"
              />
              <stop
                offset="1"
                stopColor="white"
                stopOpacity="0.01"
              />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className={classes.queueSentence}>{queueSentence}</div>
      {videoBackgroundSources && (
        <div
          className={classes.background}
          aria-hidden="true"
        >
          <video
            className={classes.video}
            loop
            muted
            playsInline
            autoPlay
            preload="preload"
          >
            {videoBackgroundSources.map((source, index) => (
              <source
                key={`backgroundVideo-${index}`}
                src={source.src}
                type={source.type}
              />
            ))}
          </video>
        </div>
      )}
    </div>
  )
}

QueueModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  queueNumber: PropTypes.string,
  queueSentence: PropTypes.string,
  videoBackgroundSources: PropTypes.any,
}

QueueModal.defaultProps = {
  className: null,
  isOpen: false,
  onClose: null,
  onOpen: null,
  queueNumber: null,
  queueSentence: null,
  videoBackgroundSources: null,
}

export default QueueModal
