import { createUseStyles } from 'react-jss'

import { colors } from '../../theme'


export default createUseStyles({
  container: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: ({ stateOpen }) => (stateOpen ? 1 : 0),
    pointerEvents: ({ stateOpen }) => (stateOpen ? 'all' : 'none'),
    transition: 'opacity 300ms ease-in',
  },
  content: {
    position: 'relative',
    zIndex: '5',
    minWidth: '25rem',
    minHeight: '23.5rem',
    overflow: 'hidden',
    border: `1px solid ${colors.pureWhite}`,
    borderRadius: '0.8rem',
    background: colors.pureBlack,
  },
  overlay: {
    position: 'absolute',
    zIndex: '1',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.5)',
  },
  imageWrapper: {
    display: 'block',
    width: '100%',
    height: '100%',
    background: colors.pureBlack,
  },
  image: {
    display: 'block',
    width: '100%',
    height: '100%',
    opacity: 0.5,
    objectFit: 'cover',
    objectPosition: 'center center',
    filter: 'blur(0.2rem)',
  },
})
