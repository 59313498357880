import React, { useState, useEffect, useMemo, useCallback } from 'react'
import * as PropTypes from 'prop-types'
import { navigate } from 'gatsby-plugin-intl'

import routes from '../../router/routes'
import router from '../../router'
import { useIntl } from '../../intl/intl'
import { withMemo, withWebsiteOpen, withGameClosed } from '../../decorators'
import LayoutGame from '../../components/LayoutGame'
import GameComponent from '../../components/Game'
import QueueModal from '../../components/QueueModal'
import Modal from '../../components/Modal'
import Share from '../../components/Share'
import TutorialTemplate from '../../templates/Tutorial'
import useLocalStorage from '../../hooks/useLocalStorage'
import useAddThis from '../../hooks/useAddThis'
import useConfiguration from '../../hooks/useConfiguration'
import useGame from '../../hooks/useGame'
import * as appConfiguration from '../../configuration'


const Game = (props) => {
  const { pageContext, location } = props
  const t = useIntl()
  const [tutorialShown, setTutorialShown] = useState(false)
  const [queueInfo, setQueueInfo] = useState({ visible: false, queueNumber: null })
  const [halfTutorialShown, setHalfTutorialShown] = useState(false)
  const [userId, setUserId] = useState(null)
  const { userCooldownDate, shareUrl, shareImage, resetShare } = useLocalStorage()
  const { seoFallback } = useConfiguration()
  const { onGameError, onGameStatus, getUserId, isShareModalOpen, TUTORIAL_DISPLAY_TIME } = useGame({ lang: pageContext?.language, isVip: pageContext?.isVip })
  const { shareProps } = useAddThis({
    addThisAccount: t('addthis_account'),
    lang: pageContext?.language,
    url: shareUrl(),
    imageSrc: shareImage(),
    shareText: t('share_modal_message'),
    closeText: t('share_modal_close'),
    onCloseModal: () => onGameStatus('__close-share-modal__', userId),
  })

  const gameQueueProps = useMemo(() => ({
    isOpen: queueInfo.visible,
    queueNumber: queueInfo.queueNumber,
    queueSentence: t('queue_sentence'),
    videoBackgroundSources: [
      {
        src: t('background_video_path'),
        type: 'video/mp4',
      },
    ],
  }), [queueInfo, t])

  const layoutProps = useMemo(() => ({
    withHeader: false,
    seo: {
      lang: pageContext?.language ?? null,
      title: seoFallback?.title ?? '',
      description: seoFallback?.description?.description ?? '',
      image: appConfiguration?.app?.url + seoFallback?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
      url: location?.href ?? '',
      robots: 'noindex, follow',
    },
  }), [location.href, pageContext.language, seoFallback.description.description, seoFallback.picture.localFile.childImageSharp.fluid.src, seoFallback.title])

  const tutorialTemplateProps = useMemo(() => ({
    leftStickLetter: t('left_stick'),
    rightStickLetter: t('right_stick'),
    upLetter: halfTutorialShown ? t('up_key_alt') : t('up_key'),
    leftLetter: halfTutorialShown ? t('left_key_alt') : t('left_key'),
    downLetter: halfTutorialShown ? t('down_key_alt') : t('down_key'),
    rightLetter: halfTutorialShown ? t('right_key_alt') : t('right_key'),
    rotateText: t('tutorial_mobile_rotate'),
    desktopText: t('tutorial_desktop_message'),
    mobileText: t('tutorial_mobile_message'),
  }), [halfTutorialShown, t])

  const gameStatusWithUid = useCallback((s) => {
    if (s === 'firstframearrived') {
      setQueueInfo({ visible: false, queueNumber: null })
    }
    onGameStatus(s, userId)
  }, [onGameStatus, userId, setQueueInfo])

  const gameStart = useCallback(() => {
    resetShare()
  }, [resetShare])

  const gamePlayingQueue = useCallback((queueNumber) => {
    setQueueInfo({ visible: true, queueNumber })
  }, [setQueueInfo])

  const gameProps = useMemo(() => ({
    gameOn: true,
    userId,
    onGameStatus: gameStatusWithUid,
    onGameError,
    onGameStart: gameStart,
    onGamePlayingQueue: gamePlayingQueue,
    gameParameter: pageContext?.isVip
      ? `Balenciaga${pageContext?.language !== 'zh' ? 'WW' : 'CN'}VIP.Balenciaga.pc.2020`
      : `Balenciaga${pageContext?.language !== 'zh' ? 'WW' : 'CN'}.Balenciaga.pc.2020`,
    serverParameter: pageContext?.language !== 'zh'
      ? 'https://bb-h5.ugamenow.com'
      : 'https://bb-h5.videogame1.balenciaga.cn',
    jsVendorParameter: pageContext?.language !== 'zh'
      ? 'https://bb-h5.ugamenow.com/resources/onnine3.js'
      : 'https://bb-h5.videogame1.balenciaga.cn/resources/onnine3.js',
  }), [userId, gameStatusWithUid, onGameError, gameStart, gamePlayingQueue, pageContext.isVip, pageContext.language])

  // Mount
  useEffect(() => {
    const timer = setTimeout(() => {
      setTutorialShown(true)
    }, TUTORIAL_DISPLAY_TIME)

    return () => clearTimeout(timer)
  }, [TUTORIAL_DISPLAY_TIME])

  useEffect(() => {
    if (pageContext?.language === 'zh') {
      return
    }

    const changeLocaleTimer = setTimeout(() => {
      setHalfTutorialShown(true)
    }, TUTORIAL_DISPLAY_TIME / 2)

    return () => clearTimeout(changeLocaleTimer)
  }, [pageContext, TUTORIAL_DISPLAY_TIME])

  // User id
  useEffect(() => {
    if (!userId) {
      // Set userId
      const uid = getUserId()

      setUserId(uid)
    }
  }, [getUserId, userId])

  useEffect(() => {
    if (userCooldownDate) {
      navigate(router(pageContext?.isVip ? routes.vipGameWait.path : routes.gameWait.path))
    }
  }, [userCooldownDate, tutorialShown, gameProps, pageContext])

  return (
    <LayoutGame {...layoutProps}>
      <QueueModal {...gameQueueProps} />
      <Modal isOpen={isShareModalOpen}>
        <Share {...shareProps} />
      </Modal>
      {tutorialShown && userId ? <GameComponent {...gameProps} /> : !userCooldownDate ? <TutorialTemplate {...tutorialTemplateProps} /> : null}
    </LayoutGame>
  )
}

Game.propTypes = {
  // eslint-disable-next-line
  pageContext: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
}

Game.defaultProps = {
  pageContext: null,
  location: null,
}

export default withGameClosed(withWebsiteOpen(withMemo()(Game)))
