import { createUseStyles } from 'react-jss'

import { desktop, colors, textStyles } from '../../theme'


export default createUseStyles({
  container: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  /* mobile */
  mobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    '& > *:not(.is-notstaggered)': {
      opacity: 0, // to prevent FOUC
    },
    ...desktop({
      display: 'none',
    }),
  },
  sticks: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '3.5rem',
  },
  stickIcon: {},
  stick: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '9.5rem',
    height: '9.5rem',
    margin: '0 3rem',
    ...textStyles.stickKey,
    '& > $stickIcon': {
      position: 'absolute',
    },
    '& > span': {
      color: colors.pureWhite,
    },
  },
  stickLetter: {
    transform: 'translateY(2px)',
  },
  mobileCopy: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.pureWhite,
    marginBottom: '3.5rem',
    ...textStyles.tutorialTextMobile,
  },
  rotate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rotateInner: {
    display: 'flex',
    width: '9.5rem',
    height: '9.5rem',
    alignItems: 'center',
    ...textStyles.tutorialTextMobile,
    '& > span:nth-child(2)': {
      textAlign: 'center',
      color: colors.pureWhite,
      padding: '1rem',
    },
  },
  rotateIcon: {
    position: 'absolute',
    width: '9.5rem',
    height: '9.5rem',
  },
  /* desktop */
  desktop: {
    display: 'none',
    '& > *:not(.is-notstaggered)': {
      opacity: 0, // to prevent FOUC
    },
    ...desktop({
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    }),
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '12.5rem',
  },
  control: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
  },
  controlLeft: {
    justifyContent: 'flex-end',
    paddingRight: '5rem',
  },
  controlRight: {
    alignItems: 'flex-start',
    paddingLeft: '5rem',
  },
  keyboard: {
    display: 'flex',
    width: '20rem',
    justifyContent: 'center',
  },
  direction: {
    display: 'flex',
    width: '20rem',
    justifyContent: 'center',
  },
  key: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '4.8rem',
    height: '4.8rem',
    border: '1px solid white',
    borderRadius: '0.4rem',
    paddingTop: '0.4rem',
    margin: '1rem',
    color: colors.pureWhite,
    ...textStyles.keyboardKey,
  },
  keyUp: {
    '& $keyArrow': {
      bottom: '100%',
      left: '50%',
      transform: 'translate(-50%, -1.2rem)',
    },
  },
  keyLeft: {
    '& $keyArrow': {
      right: '100%',
      top: '50%',
      transform: 'translate(-1rem, -50%) rotate(-90deg)',
      transformOrigin: 'center',
    },
  },
  keyDown: {
    '& $keyArrow': {
      top: '100%',
      left: '50%',
      transform: 'translate(-50%, 1.2rem) rotate(180deg)',
    },
  },
  keyRight: {
    '& $keyArrow': {
      left: '100%',
      top: '50%',
      transform: 'translate(1rem, -50%) rotate(90deg)',
      transformOrigin: 'center',
    },
  },
  keyArrow: {
    position: 'absolute',
  },
  arrowLeft: {
    transform: 'rotate(180deg)',
  },
  arrowTop: {
    transform: 'rotate(-90deg)',
  },
  arrowDown: {
    transform: 'rotate(90deg)',
  },
  desktopCopy: {
    color: colors.pureWhite,
    ...textStyles.tutorialText,
  },
})
