/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import withMemo from '../../decorators/withMemo'
import Link from '../Link'
import Icon from '../Icon'
import AccessibleHiddenText from '../AccessibleHiddenText'
import { safeCall } from '../../utils/safeCall'

import useStyles from './styles'


const Share = (props) => {
  const classes = useStyles(props)
  const { className, screenshot, links, textClose, textShare, onClose } = props

  const handleClose = useCallback(() => {
    console.log('handle close')
    safeCall(onClose)
  }, [onClose])

  return (
    <nav className={cx(className, classes.container)}>
      <div>
        <figure className={classes.screenshot}>
          <img
            src={screenshot.imageUrl}
            className={classes.screenshotImage}
            alt=" "
          />
        </figure>
        <div className={classes.content}>
          <p className={classes.text}>{textShare}</p>
          {links && (
            <ul className={classes.list}>
              {links.map((link, index) => (
                <li
                  key={`share-link-${index}`}
                  className={classes.item}
                >
                  <Link
                    link={link.link}
                    className={classes.link}
                    {...link.otherProps}
                  >
                    <AccessibleHiddenText text={link.text} />
                    <Icon
                      icon={link.icon}
                      className={classes.logoMain}
                      aria-hidden="true"
                    />
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <div className={classes.actions}>
            <button
              type="button"
              className={classes.close}
              onClick={handleClose}
            >
              {textClose}
            </button>
          </div>
        </div>
      </div>
    </nav>
  )
}

Share.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  screenshot: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      icon: PropTypes.object,
      link: PropTypes.string,
      text: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      otherProps: PropTypes.object,
    })
  ),
  textClose: PropTypes.string,
  textShare: PropTypes.string,
  onClose: PropTypes.func,
}

Share.defaultProps = {
  className: null,
  screenshot: null,
  links: null,
  textClose: '',
  textShare: '',
  onClose: null,
}

export default withMemo()(Share)
