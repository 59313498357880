import { createUseStyles } from 'react-jss'

import { colors, desktop } from '../../theme'


export default createUseStyles({
  container: {
    display: 'block',
    maxWidth: '25rem',
    margin: '0 auto',
    ...desktop({
      maxWidth: '34.5rem',
    }),
  },
  screenshot: {
    display: 'block',
    margin: 0,
  },
  screenshotImage: {
    display: 'block',
    margin: 0,
  },
  content: {
    padding: '2rem 1rem 2rem',
    '& > *': {
      margin: 0,
    },
    '& > * + *': {
      marginTop: '1.4rem',
    },
    ...desktop({
      padding: '3.5rem 2rem 3.5rem',
    }),
  },
  text: {
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: '1.3',
  },
  list: {
    padding: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    listStyle: 'none inside',
    '& > *': {
      margin: 0,
      flex: '0 0 auto',
    },
    '& > * + *': {
      marginLeft: '1.4rem',
    },
  },
  item: {},
  link: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2rem',
    height: '2rem',
    border: `1px solid ${colors.pureWhite}`,
    borderRadius: '0.2rem',
    '& > *': {
      flex: '0 0 auto',
    },
  },
  actions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      margin: 0,
      flex: '0 0 auto',
    },
    '& > * + *': {
      marginLeft: '1.4rem',
    },
  },
  close: {
    background: 'transparent',
    outline: '0 none',
    color: colors.pureWhite,
    textDecoration: 'underline',
    fontSize: '1rem',
    lineHeight: '1.3',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
})
