// https://www.addthis.com/academy/addthis-sharing-endpoints/
import { useMemo, useCallback } from 'react'

import { iconsKeys } from '../components/Icon/Icon.assets'


const useAddThis = ({ addThisAccount, lang, url, imageSrc, closeText, shareText, onCloseModal }) => {
  const addThisUrl = 'http://api.addthis.com/oexchange/0.8'
  const addThisEndPoint = '/forward/SERVICE_CODE/offer'

  const addThisParams = useMemo(() => ({
    url,
    pubid: addThisAccount,
    // optional
    title: '',
    description: '',
    screenshot: '',
    email_template: '',
    ct: '',
  }), [url, addThisAccount])

  const generateAddThisUrl = useCallback((u, endpoint, serviceCode, params) => `${u}${endpoint.replace('SERVICE_CODE', serviceCode)}?${Object.entries(params).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')}`, [])

  const shareProps = useMemo(() => ({
    onClose: onCloseModal,
    textShare: shareText,
    textClose: closeText,
    screenshot: {
      imageUrl: imageSrc,
    },
    links: [
      ...lang === 'zh' ? [
        {
          icon: iconsKeys.SocialWeibo,
          link: generateAddThisUrl(addThisUrl, addThisEndPoint, 'sinaweibo', addThisParams),
          text: 'weibo',
          otherProps: {
            target: '_blank',
          },
        }, {
          icon: iconsKeys.SocialWechat,
          link: generateAddThisUrl(addThisUrl, addThisEndPoint, 'wechat', addThisParams),
          text: 'wechat',
          otherProps: {
            target: '_blank',
          },
        }] : [{
        icon: iconsKeys.SocialFacebook,
        link: generateAddThisUrl(addThisUrl, addThisEndPoint, 'facebook', addThisParams),
        text: 'facebook',
        otherProps: {
          target: '_blank',
        },
      }, {
        icon: iconsKeys.SocialTwitter,
        link: generateAddThisUrl(addThisUrl, addThisEndPoint, 'twitter', addThisParams),
        text: 'twitter',
        otherProps: {
          target: '_blank',
        },
      }], {
        icon: iconsKeys.SocialDownload,
        link: imageSrc,
        text: 'download',
        otherProps: {
          target: '_blank',
          download: 'BalenciagaFall21Screenshot.jpg',
        },
      },
    ],
  }), [onCloseModal, shareText, closeText, imageSrc, lang, generateAddThisUrl, addThisParams])


  return {
    shareProps,
  }
}

export default useAddThis
