import { createUseStyles } from 'react-jss'

import { textStyles, desktop, colors, notDesktop } from '../../theme'


export default createUseStyles({
  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  container: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: 'black',
    opacity: ({ stateOpen }) => (stateOpen ? 1 : 0),
    pointerEvents: ({ stateOpen }) => (stateOpen ? 'all' : 'none'),
    transition: 'opacity 300ms ease-in',
  },
  logoMain: {
    display: 'flex',
    width: '11rem',
    height: '1.4rem',
    background: colors.pureBlack,
    outline: `0.4rem solid ${colors.pureBlack}`,
    zIndex: 2,
    '& svg': {
      width: '100%',
      height: 'auto',
    },
    ...desktop({
      width: '13rem',
      height: '1.5rem',
    }),
  },
  countDownWrapper: {
    display: 'flex',
    zIndex: 2,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    ...notDesktop({
      '@media (orientation: landscape)': {
        height: '40rem',
      },
    }),
  },
  countdown: {
    zIndex: 2,
    color: colors.pureWhite,
    ...textStyles.queueNumberMobile,
    ...desktop({
      ...textStyles.queueNumber,
    }),
  },
  countDownImage: {
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    left: '50%',
    height: '23.2rem',
    width: '23.2rem',
    marginTop: '-11.6rem',
    marginLeft: '-11.6rem',
    transformOrigin: 'center center',
    animation: '$rotating 12s linear infinite',
    willChange: 'transform',
    ...notDesktop({
      '@media (orientation: landscape)': {
        opacity: 0,
      },
    }),
  },
  queueSentence: {
    zIndex: 2,
    color: colors.pureWhite,
    ...textStyles.queueNumberMobile,
    fontSize: '1.2rem',
    textAlign: 'center',
    ...desktop({
      ...textStyles.queueNumber,
      fontSize: '1.6rem',
    }),
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: '1',
    overflow: 'hidden',
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'cover',
  },
})
