import { createUseStyles } from 'react-jss'


export default createUseStyles({
  container: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 10,
    pointerEvents: ({ stateGameOn }) => (stateGameOn ? 'all' : 'none'),
  },
  gamePlayContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    opacity: ({ stateGameOn }) => (stateGameOn ? 1 : 0),
    pointerEvents: ({ stateGameOn }) => (stateGameOn ? 'all' : 'none'),
    transition: 'opacity 300ms ease-in',
  },
})
