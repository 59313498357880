import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'

import staggerAnimation from '../../utils/staggerAnimation'
import withMemo from '../../decorators/withMemo'
import Icon from '../../components/Icon'
import { iconsKeys } from '../../components/Icon/Icon.assets'

import useStyles from './styles'


const TutorialTemplate = (props) => {
  const classes = useStyles(props)
  const { className, leftStickLetter, rightStickLetter, rotateText, upLetter, leftLetter, downLetter, rightLetter, mobileText, desktopText } = props

  // animations
  const $animatedMobileWrapper = useRef()
  const $animatedDesktopWrapper = useRef()

  useEffect(() => {
    const timeline = gsap.timeline()

    if ($animatedMobileWrapper.current) {
      const animatedMobileChildren = Array
        .from($animatedMobileWrapper.current.children)
        .filter((child) => !child.classList.contains('is-notstaggered'))

      staggerAnimation(0, animatedMobileChildren, timeline, 0, '+=0.2', 0.5)
      timeline.play()
    }

    // cleanup
    return () => {
      timeline.clear()
    }
  }, [])

  useEffect(() => {
    const timeline = gsap.timeline()

    if ($animatedDesktopWrapper.current) {
      const animatedDesktopChildren = Array
        .from($animatedDesktopWrapper.current.children)
        .filter((child) => !child.classList.contains('is-notstaggered'))

      staggerAnimation(0, animatedDesktopChildren, timeline, 0, '+=0.2', 0.5)
      timeline.play()
    }

    // cleanup
    return () => {
      timeline.clear()
    }
  }, [])

  return (
    <main className={cx(className, classes.container)}>
      <div
        className={classes.mobile}
        ref={$animatedMobileWrapper}
      >
        <div className={classes.sticks}>
          <div className={classes.stick}>
            <Icon
              className={classes.stickIcon}
              icon={iconsKeys.LeftStick}
            />
            <span className={classes.stickLetter}>{leftStickLetter}</span>
          </div>
          <div className={classes.stick}>
            <Icon
              className={classes.stickIcon}
              icon={iconsKeys.RightStick}
            />
            <span className={classes.stickLetter}>{rightStickLetter}</span>
          </div>
        </div>
        <div className={classes.mobileCopy}>
          {mobileText && <p>{mobileText}</p>}
        </div>
        <div className={classes.rotate}>
          <div className={classes.rotateInner}>
            <Icon
              className={classes.rotateIcon}
              icon={iconsKeys.RotateScreen}
            />
            <span>{rotateText}</span>
          </div>
        </div>
      </div>
      <div
        className={classes.desktop}
        ref={$animatedDesktopWrapper}
      >
        <div className={classes.controls}>
          <div className={cx(classes.control, classes.controlLeft)}>
            <div className={classes.keyboard}>
              <div className={cx(classes.key, classes.keyUp)}>
                {upLetter}
                <svg
                  className={classes.keyArrow}
                  aria-hidden="true"
                  width="20"
                  height="10"
                  viewBox="0 0 20 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 9.33347L10.0001 0L20 9.33252L19.2848 10L10.0001 1.33327L0.714177 10L0 9.33347Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div className={classes.keyboard}>
              <div className={cx(classes.key, classes.keyLeft)}>
                {leftLetter}
                <svg
                  className={classes.keyArrow}
                  aria-hidden="true"
                  width="20"
                  height="10"
                  viewBox="0 0 20 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 9.33347L10.0001 0L20 9.33252L19.2848 10L10.0001 1.33327L0.714177 10L0 9.33347Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className={cx(classes.key, classes.keyDown)}>
                {downLetter}
                <svg
                  className={classes.keyArrow}
                  aria-hidden="true"
                  width="20"
                  height="10"
                  viewBox="0 0 20 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 9.33347L10.0001 0L20 9.33252L19.2848 10L10.0001 1.33327L0.714177 10L0 9.33347Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className={cx(classes.key, classes.keyRight)}>
                {rightLetter}
                <svg
                  className={classes.keyArrow}
                  aria-hidden="true"
                  width="20"
                  height="10"
                  viewBox="0 0 20 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 9.33347L10.0001 0L20 9.33252L19.2848 10L10.0001 1.33327L0.714177 10L0 9.33347Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className={cx(classes.control, classes.controlRight)}>
            <div className={classes.direction}>
              <Icon icon={iconsKeys.Mouse} />
            </div>
          </div>
        </div>
        <div className={classes.desktopCopy}>
          {desktopText && <p>{desktopText}</p>}
        </div>
      </div>
    </main>
  )
}

export const TutorialTemplatePropTypes = {
  className: PropTypes.string,
  leftStickLetter: PropTypes.string,
  rightStickLetter: PropTypes.string,
  rotateText: PropTypes.string,
  upLetter: PropTypes.string,
  leftLetter: PropTypes.string,
  downLetter: PropTypes.string,
  rightLetter: PropTypes.string,
  desktopText: PropTypes.string,
  mobileText: PropTypes.string,
}

TutorialTemplate.propTypes = TutorialTemplatePropTypes

TutorialTemplate.defaultProps = {
  className: null,
  leftStickLetter: '',
  rightStickLetter: '',
  rotateText: '',
  upLetter: '',
  leftLetter: '',
  downLetter: '',
  rightLetter: '',
  desktopText: '',
  mobileText: '',
}

export default withMemo()(TutorialTemplate)
