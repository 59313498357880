/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { safeCall } from '../../utils/safeCall'

import useStyles from './styles'


const Game = (props) => {
  const { className, gameOn, onGameError, onGameStatus, onGameStart, onGamePlayingQueue, id, userId, gameParameter, serverParameter, jsVendorParameter } = props

  const [onNineLoaded, setOnNineLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [onNineInstance, setOnNineInstance] = useState(false)
  const [stateGameOn, setStateGameOn] = useState(gameOn)

  const classes = useStyles({ ...props, stateGameOn })

  const handleGameError = useCallback((e) => {
    setError(true)
    safeCall(onGameError, e)
  }, [onGameError])

  const handleGameStatus = useCallback((s) => {
    safeCall(onGameStatus, s)
  }, [onGameStatus])

  const handleGameStart = useCallback(() => {
    safeCall(onGameStart)
  }, [onGameStart])

  const handlePlayingQueue = useCallback((number) => {
    safeCall(onGamePlayingQueue, number)
  }, [onGamePlayingQueue])

  const onWindowResize = useCallback(() => {
    if (onNineInstance) {
      setTimeout(() => {
        onNineInstance.setSize(document.documentElement.clientWidth, document.documentElement.clientHeight)
      }, 200)
    }
  }, [onNineInstance])

  useEffect(() => {
    setStateGameOn(gameOn)
  }, [gameOn])

  useEffect(() => {
    window.addEventListener('resize', onWindowResize)

    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [onWindowResize])

  // add script
  useEffect(() => {
    const script = document.createElement('script')

    script.onload = () => setOnNineLoaded(true)
    script.src = jsVendorParameter
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [jsVendorParameter])

  // start game
  useEffect(() => {
    const config = {
      game: gameParameter,
      server: serverParameter,
      width: document?.documentElement?.clientWidth,
      height: document?.documentElement?.clientHeight,
      target: `#${id}`,
      auth: {
        id: userId,
        provider_id: 'anonymous',
        channel: 'bb_h5',
      },
      launchParams: {
        session: 'new',
        'launchMacro.Lang': 'en-us',
        snapshotStoreLink: `screenshots/${userId}`,
        kickoutTimeout: '-1',
        idleTimeout: '240',
        duiRefSize: 1100,
        region: 'auto',
        setting: {
          toolbar: false,
          landscape_ex: true,
          embed: true,
          simpleloading: true,
          playQueue: true,
        },
      },
    }

    let _onnine = {}

    const startGame = () => {
      if (!onNineLoaded || onNineInstance) {
        return
      }

      console.log('Starting game with', config)

      // Set gamecloud configuration
      // Initialize OnNine and get OnNine instance
      // eslint-disable-next-line
      OnNine.initializeAsync(config).then(o => {
        _onnine = o
        setOnNineInstance(o)
        handleGameStart()
        // Listen status events
        _onnine.addEventListener('status', (status) => {
          handleGameStatus(status)
        })
        // Listen error events
        // eslint-disable-next-line
        _onnine.addEventListener('error', (error) => {
          handleGameError(error)
        })
        // Playing queue
        _onnine.addEventListener('alreadyinplayqueue', (payload) => {
          handlePlayingQueue(payload?.waitingCount)
        })
        // Play game
        _onnine.play()
      })
    }

    if (!error && stateGameOn && gameParameter) {
      startGame()
    }
  }, [error, stateGameOn, handleGameError, handleGameStatus, onNineLoaded, onNineInstance, id, userId, gameParameter, serverParameter, setOnNineInstance, handleGameStart, handlePlayingQueue])

  return (
    <div className={cx(className, classes.container, 'is-notstaggered')}>
      <div
        id={id}
        className={classes.gamePlayContainer}
      />
    </div>
  )
}

Game.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  userId: PropTypes.string,
  gameOn: PropTypes.bool,
  gameParameter: PropTypes.string,
  serverParameter: PropTypes.string,
  jsVendorParameter: PropTypes.string,
  onGameError: PropTypes.func,
  onGameStatus: PropTypes.func,
  onGameStart: PropTypes.func,
  onGamePlayingQueue: PropTypes.func,
}

Game.defaultProps = {
  className: null,
  id: 'gameplay',
  userId: null,
  gameParameter: null,
  serverParameter: null,
  jsVendorParameter: null,
  gameOn: false,
  onGameError: () => { },
  onGameStatus: () => { },
  onGameStart: () => { },
  onGamePlayingQueue: () => { },
}

export default Game
